import React from 'react';
import {
  CreatorsListRole, KeyRole, CreatorCollaborator,
} from 'lib/types';
import { CreatorsListUser } from 'lib/types/users';
import { Favorite, Visibility } from '@mui/icons-material';
import { abbreviateNum } from 'lib/helpers';
import { Avatar } from 'components/Avatar';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { Avatar as Avi } from '@mui/material';

interface Props {
  user: CreatorsListUser;
}

export const CreatorInfoLarge = ({
  user,
}: Props) => {
  const getUsersRoles = () => (
    user.roles
      .filter((r: CreatorsListRole) => user.hiddenRoles
        .every((hr: KeyRole) => hr.roleId !== r.id))
      .slice(0, 3).map((r: CreatorsListRole) => (
        <div className="rounded-3xl px-6 py-2 bg-hintgray mr-2" key={r.id}>{r.name}</div>
      ))
  );
  const AVATAR_SIZE = 80;
  const getCollaborators = () => user.collaborators
    .slice(0, 5)
    .reverse()
    .filter((c: CreatorCollaborator) => !!c.avatarUrl)
    .map((c: CreatorCollaborator) => (
      <div className="pr-2" key={c.id}>
        <Avi
          sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
          className="text-3xl font-bold bg-orange"
          key={c.id}
          variant="circular"
          src={imageAvatarUrl(c.avatarUrl, 160)}
          alt={c.name || c.username || ''}
        />
      </div>
    ));

  return (
    <div className="flex gap-4 items-center">
      <div className="w-1/3 pr-6">
        <Avatar
          src={imageAvatarUrl(user.avatarUrl, 400)}
          userId={user.id}
          isPro={false}
          alt={user.name}
        />
      </div>
      <div className="w-2/3">
        <h6 className="font-bold text-5xl mb-4">
          {user.name}
        </h6>
        <div className="flex flex-wrap mb-4 text-3xl">
          {user.location && (
            <div className="mr-2">
              {user.location.name}
            </div>
          )}
          <div>
            <Favorite fontSize="large" />
            <span>{abbreviateNum(user.currentLikes, 0)}</span>
            {user.currentCombinedViews > 0
            && (
              <>
                &nbsp;
                <Visibility fontSize="large" />
                <span>{abbreviateNum(user.currentCombinedViews, 0)}</span>
              </>
            )}
          </div>
        </div>
        <div className="flex mb-4 gap-1 flex-wrap text-3xl">
          {getUsersRoles()}
        </div>
        {user.collaborators.length !== 0
        && (
          <div className="pt-2 items-center">
            <div className="pr-1 mb-4 text-2xl">Top Collaborators:</div>
            <div className="flex">
              {user.collaborators
              && getCollaborators()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
