import { CreatorCollaborator, CreatorsListRole, KeyRole } from 'lib/types';
import { CreatorsListUser } from 'lib/types/users';
import {
  Avatar as Icon, Chip,
} from '@mui/material';
import { Favorite, Visibility } from '@mui/icons-material';
import {
  abbreviateNum, userIsPro,
} from 'lib/helpers';
import { Avatar } from 'components/Avatar';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { modelToSlug } from 'lib/utils/modelSlugUtils';
import { ProfileLink } from 'components/ProfileLink';
import { SaveToListButton } from 'components/SaveToList';
import { SocialMediaRow } from 'components/SocialMediaRow';
import { CollabAvatar } from 'components/CollabAvatar';
import { MailButton } from 'components/MailButton';
import { CreatorCardClickContext } from 'lib/types/events';

interface Props {
  user: CreatorsListUser;
  showLinks?: boolean;
  showPro?: boolean;
  hideSave?: boolean;
  context?: CreatorCardClickContext;
}

export const CreatorInfo = ({
  user,
  showLinks = false,
  showPro = false,
  hideSave = false,
  context,
}: Props) => {
  const getUserRoles = () => {
    if (!user?.roles?.length && !!user.defaultRole) {
      return (
        <Chip
          clickable={false}
          label={user.defaultRole.name}
        />
      );
    }

    return user.roles
      .filter((r: CreatorsListRole) => user.hiddenRoles
        .every((hr: KeyRole) => hr.roleId !== r.id))
      .slice(0, 3).map((r: CreatorsListRole) => (
        <ProfileLink
          key={r.id}
          user={user}
          query={{ role: modelToSlug(r, 'name') }}
          disabled={!showLinks || !user.termsAcknowledged}
          context={context}
        >

          <Chip
            clickable={showLinks && user.termsAcknowledged}
            avatar={r?.roleCount ? <Icon sx={{ backgroundColor: 'white' }}>{abbreviateNum(r?.roleCount || 0, 0)}</Icon> : undefined}
            label={r.name}
          />
        </ProfileLink>
      ));
  };

  const getCollaborators = () => user.collaborators
    .slice(0, 5).map((c: CreatorCollaborator) => (
      <CollabAvatar
        key={c.id}
        collab={c}
        disabled={!showLinks}
      />
    ));

  const getStats = () => {
    if (!user?.currentLikes && !user?.currentCombinedViews) {
      return null;
    }

    return (
      <div className="mr-2">
        {user?.currentLikes > 0 && (
          <>
            <Favorite fontSize="small" />
            <span>{abbreviateNum(user.currentLikes, 0)}</span>
          </>
        )}
        {user.currentCombinedViews > 0
          && (
            <>
              &nbsp;
              <Visibility fontSize="small" />
              <span>{abbreviateNum(user.currentCombinedViews, 0)}</span>
            </>
          )}
      </div>
    );
  };

  if (!user?.username) {
    return null;
  }

  return (
    <div className="flex flex-wrap w-full group">
      <div className="flex w-full md:w-2/3 gap-4">
        <div className="flex gap-2 w-1/3 lg:w-1/4">
          <div className="text-orange flex flex-col gap-2">
            {!hideSave
              && (
                <SaveToListButton
                  userId={user.id}
                  listsFromProps={user.savedToLists || []}
                />
              )}
            {user?.contactAllowed && !hideSave
              && <MailButton user={user} />}
          </div>
          <ProfileLink
            user={user}
            disabled={!showLinks}
            linkToExplore={!user.termsAcknowledged}
            context={context}
          >
            <Avatar
              src={imageAvatarUrl(user.avatarUrl, 160)}
              userId={user.id}
              isPro={showPro && userIsPro(user)}
              alt={user.name}
              width={140}
              height={140}
            />
          </ProfileLink>
        </div>
        <div className="w-2/3 lg:w-3/4">
          <div className="flex gap-2">
            <h6 className="font-bold text-xl">
              <ProfileLink
                user={user}
                disabled={!showLinks}
                linkToExplore={!user.termsAcknowledged}
                context={context}
              >
                {user.name}
              </ProfileLink>
            </h6>
            {showLinks
              && (
                <div className="hidden sm:group-hover:block">
                  <SocialMediaRow profileUser={user} />
                </div>
              )}
          </div>
          <div className="flex flex-wrap">
            {getStats()}
            <div>{user.location?.name}</div>
          </div>
          <div className="flex pt-2 gap-1 flex-wrap">
            {getUserRoles()}
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 py-2 md:py-0">
        {user.collaborators.length !== 0
        && (
          <>
            <div className="pr-1 pb-2 text-sm font-semibold">Top Collaborators:</div>
            <div className="flex gap-1 flex-wrap">
              {user.collaborators
                && getCollaborators()}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
